import React, { useState } from "react";
import styles from "./Author.module.css";
import { useNavigate } from "react-router-dom";
import { HiOutlinePencil } from "react-icons/hi";
import { FaTrashCan } from "react-icons/fa6";
import { adminService } from "service/Admin.service";
import { queryClient } from "index";
import { useModalStore } from "providers/ModalStoreProvider";
import { setModal } from "store/modalStore/action";
import { errorCatch } from "utils/errorCatch";
import { IoMdClose } from "react-icons/io";

const Author = ({
  count,
  id,
  name,
  surname,
  isUpdateMode,
  setIsUpdateMode,
  email
}) => {
  const nav = useNavigate();
  const onClickNav = (e, id) => {
    if (e.target.tagName === "DIV") {
      nav(`/admin/author/${id}`);
    }
  };
  const [, setModalStore] = useModalStore();
  const [confirm, setConfirm] = useState(false);

  const handleClickDelete = async () => {
    try {
      const data = await adminService.deleteAuthor(id);
      queryClient.invalidateQueries({
        queryKey: ["author"],
      });
      setConfirm(!confirm);
      setModalStore(setModal(`Автор ${name} ${surname} удален!`, "OK"));
      return data;
    } catch (error) {
      setModalStore(setModal(errorCatch(error), "OK"));
    }
    return;
  };

  return (
    <div className={styles.wrapperAuthor} onClick={(e) => onClickNav(e, id)}>
      {confirm ? (
        <div className={styles.confirm}>
          <div className={styles.confirm_content}>
            <IoMdClose color="#B4B4B4" onClick={() => setConfirm(!confirm)} />
            <p>
              Вы уверены в удалении автора {name} {surname}?
            </p>
            <div>
              <button
                className={styles.confirm_button_cancel}
                onClick={() => setConfirm(!confirm)}
              >
                Оставить
              </button>
              <button
                className={styles.confirm_button}
                onClick={() => handleClickDelete()}
              >
                Удалить
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className={styles.first}>
        <p>{count}</p>
        <p>{name + " " + surname}</p>
        <p className={styles.email}>{email}</p>
      </div>
      <div className={styles.end}>
        <HiOutlinePencil
          onClick={() => {
            setIsUpdateMode(true);
          }}
        />
        <FaTrashCan onClick={() => setConfirm(!confirm)} />
      </div>
    </div>
  );
};

export default Author;