import React from "react";

const Books = () => {
  return (
    <svg
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9739 12.9869L16.1645 9.89112L13.6148 0.595671C13.5597 0.390626 13.4248 0.215571 13.2397 0.108843C13.0546 0.00211402 12.8344 -0.0275903 12.6272 0.0262348L9.49471 0.828258C9.41995 0.746166 9.32887 0.680279 9.22718 0.634732C9.1255 0.589186 9.01541 0.564964 8.90382 0.56359H0.809438C0.594762 0.56359 0.388878 0.648089 0.237079 0.798497C0.0852798 0.948906 0 1.1529 0 1.36561V14.198C0 14.4107 0.0852798 14.6147 0.237079 14.7651C0.388878 14.9155 0.594762 15 0.809438 15H8.90382C9.1185 15 9.32438 14.9155 9.47618 14.7651C9.62798 14.6147 9.71326 14.4107 9.71326 14.198V7.7818L11.494 14.3744C11.5415 14.5494 11.6473 14.7033 11.7943 14.8111C11.9412 14.9188 12.1207 14.9741 12.3035 14.9679C12.3734 14.9758 12.444 14.9758 12.5139 14.9679L16.4235 13.9333C16.5269 13.9059 16.6237 13.8585 16.7085 13.7938C16.7932 13.7291 16.8642 13.6484 16.9173 13.5564C17.0026 13.3789 17.0227 13.1775 16.9739 12.9869ZM4.02291 13.428H1.59459V11.824H4.02291V13.428ZM4.02291 10.2199H1.59459V5.40781H4.02291V10.2199ZM4.02291 3.80376H1.59459V2.19972H4.02291V3.80376ZM8.0701 13.428H5.64179V11.824H8.0701V13.428ZM8.0701 10.2199H5.64179V5.40781H8.0701V10.2199ZM8.0701 3.80376H5.64179V2.19972H8.0701V3.80376ZM9.89134 2.40824L12.2387 1.78266L12.6596 3.33057L10.3122 3.95615L9.89134 2.40824ZM11.9878 10.1558L10.7332 5.50405L13.0805 4.87847L14.3352 9.5302L11.9878 10.1558ZM12.7972 13.2516L12.3763 11.7037L14.7237 11.0781L15.1446 12.626L12.7972 13.2516Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Books;
