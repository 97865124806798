import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { baseUrl } from "../../config";
import Button from "../Base/Button/Button";
import Input from "../Base/Input/Input";
import "./forgot.css";
import { useModalStore } from "../../providers/ModalStoreProvider";
import { setModal } from "../../store/modalStore/action";
import { CountDown } from "components/Timer/timer";
import { errorCatch } from "utils/errorCatch";


const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export default function Forgot() {
  const navigate = useNavigate();
  const [sended, setSended] = useState(false);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [repeat, setRepeat] = useState(false)
  const [, setModalStore] = useModalStore()
 


  const handleSendEmail = async () => {
    try {
      await axios.post(`${baseUrl}/password-reset`, {
        email,
        role: "AUTHOR",
      });
      setModalStore(setModal("Код отправлен", 'OK'))
      setTimeout(()=> {setRepeat(true)},900 * 1000 )
      setSended(true);
    } catch (e) {
      setModalStore(setModal(`${errorCatch(e)}`, 'OK'))
    }
  };





  
  const handleCheckCode = async () => {
    try {
      const { data } = await axios.post(`${baseUrl}/password-reset/confirm`, {
        code,
      });
      return navigate("/forgot-password/reset", { state: data.link });
    } catch (e) {
      setModalStore(setModal(`${e.message}`, 'OK'))
    }
  };

  return (
    <div className="forgot__container">
      <div className="forgot">
        <h1>Восстановление пароля</h1>
        <div className="reset redirectLink">
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type={"text"}
            placeholder={"E-mail"}
            readonly={sended}
          />
          {!sended ? (
            <>
              <p className="grey forgot__label">
                Для восстановления пароля введите почту, на которую
                зарегистрирован аккаунт. Мы отправим Вам проверочный код.
              </p>
              <Button
                disabled={email.match(emailRegex) ? false : true}
                onClick={handleSendEmail}
                value={"Продолжить"}
              />
            </>
          ) : (
            <>
              <Input
                type={"text"}
                value={code.code}
                onChange={(e) => {
                  setCode(e.target.value);
                }}
                placeholder={"Проверочный код"}
              />
              <p className="grey forgot__label">
               Для восстановления пароля введите почту, на которую
                зарегистрирован аккаунт. Мы отправили Вам проверочный код. Код
                действителен </p> {<CountDown/>}<p className="grey forgot__label"> минут.
              </p>
              {repeat ? <span onClick={handleSendEmail} className="orange">
                Отправить код повторно
              </span> : ''}
              <Button
                // disabled={code.code.length == 6 ? false : true}
                onClick={handleCheckCode}
                value={"Продолжить"}
              />
            </>
          )}
          <div className="route">
            <Link to={"/sign-up"}>Регистрация</Link>
            <Link to={"/login"}>Вход</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
