import axios from "axios";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../../Base/Button/Button";
import Input from "../../Base/Input/Input";
import "../forgot.css";
import { useModalStore } from "providers/ModalStoreProvider";
import { setModal } from "store/modalStore/action";
import { errorCatch } from "utils/errorCatch";
import { uploadsUrl } from "config";
import { isPasswordValid } from "utils/validation";



export default function Reset() {
  const [, setModalStore] = useModalStore()
  const location = useLocation();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");

  const handleChangePassword = async () => {
    try {
      const { data } = await axios.put(`${uploadsUrl}${location.state}`, {
        password,
        confirm,
        role: "AUTHOR",
      });
      navigate("/login");
      setModalStore(setModal(data.message, "OK"))
    } catch (e) {
      setModalStore(setModal(`${errorCatch(e)}`, "OK"))
    }
  };

  return (
    <div className='forgot__container'>
      <div className="forgot">
        <h1>Восстановление пароля</h1>
        <div className="reset redirectLink">
          <Input
            type={"password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={"Придумайте пароль"}
          />
          {!isPasswordValid(password) && (
            <span>Пароль должен быть длиннее 8 символов и содержать заглавную букву, цифру и спец. символ!</span>
          )}
          <Input
            type={"password"}
            value={confirm}
            onChange={(e) => setConfirm(e.target.value)}
            placeholder={"Повторите пароль"}
          />
          
          <Button
            disabled={!isPasswordValid(confirm) }
            value={"Сменить пароль"}
            onClick={handleChangePassword}
          />
          <div className="route">
            <Link to={"/sign-up"}>Регистрация</Link>
            <Link to={"/login"}>Вход</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
