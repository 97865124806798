import React, { useState } from "react";
import styles from "./Sorted.module.css";
import SortPopular from "./SortPopular/SortPopular";


const Sorted = ({ setFilter, isDesc, setIsDesc }) => {
  const a = [
    {
      name: "по новизне",
      filter: "createdAt",
    },
    {
      name: "по показам рекламы",
      filter: "advertisements",
    },
    {
      name: "по имени автора",
      filter: "name",
    },
    {
      name: "опубликованные",
      filter: "published",
    },
    {
      name: "не опубликованные",
      filter: "noPublished",
    },
  ];
 
  const [state, setState] = useState(0);
  return (
    <div className={styles.wrapperSort}>
      
        <SortPopular
          items={a}
          setFilter={setFilter}
          isDesc={isDesc}
          setIsDesc={setIsDesc}
          count={state}
          setCount={setState}
        />
    </div>
  );
};
export default Sorted;
