import React, { useState } from "react";
import Author from "./Author";
import UpdateAuthor from "../updateAuthor/UpdateAuthor";

const FullAuthor = ({ count, id, name, surname, status, email }) => {
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  return (
    <>
      <Author
        count={count}
        id={id}
        name={name}
        surname={surname}
        setIsUpdateMode={setIsUpdateMode}
        email={email}
      />
      {isUpdateMode && (
        <UpdateAuthor  isUpdateMode={isUpdateMode} id={id} setIsUpdateMode={setIsUpdateMode} status = {status} />
      )}
    </>
  );
};

export default FullAuthor;