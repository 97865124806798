import React, { useRef, useState, useCallback, useEffect } from "react";
import styles from "./File.module.css";
import * as bookStoreActions from "../../../../store/bookStore/actions";
import { IoMdClose } from "react-icons/io";
import { BookstoreProvider, useBookStore } from "providers/BookStoreProvider";
import { useModalStore } from "providers/ModalStoreProvider";
import { setModal } from "store/modalStore/action";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { GrAttachment, GrEdit } from "react-icons/gr";

export const File = () => {
  const fileRef = useRef(null);
  const [, setModalStore] = useModalStore();
  const [chapters, setChapters] = useState();
  const [bookStore, dispatch] = useBookStore();
  const [confirm, setConfirm] = useState(false);
  const [idChapter, setIdChapter] = useState();

  const getChapters = useCallback(() => {
    const chaptersList = bookStore?.chapters
      ? Object.keys(bookStore.chapters).map((chapterId) => ({
          ...bookStore.chapters[chapterId],
          id: chapterId,
        }))
      : [];

    return chaptersList;
  }, [bookStore.chapters]);

  const handleDeleteChapter = () => {
    dispatch(bookStoreActions.deleteChapter(idChapter));
    setConfirm(!confirm);
  };

  const handlerChangeChapter = (e, id) => {
    dispatch(bookStoreActions.changeChapter(id, "name", e.target.value));
  };

  useEffect(() => {
    setChapters(getChapters());
  }, [getChapters]);

  const handleFileChange = (event, id) => {
    const validTypes = ["txt"];
    const type = event.target.files[0].name.split(".").pop();
    if (!validTypes.includes(type)) {
      setModalStore(
        setModal(
          "Возможно загрузка следующиx фарматов файла: .txt",
          "Редактировать",
        ),
      );
    }
    if (event.target.files[0]?.size > 2097152) {
      setModalStore(setModal("Размер файла превышает 2мб", "Редактировать"));
    }

    if (validTypes.includes(type) && event.target.files[0]?.size < 2097152) {
      dispatch(
        bookStoreActions.changeChapter(id, "file", event.target.files[0]),
      );
      setModalStore(setModal("Файл успешно загружен", "OK"));
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const newChapters = Array.from(chapters);
    const [reorderedChapter] = newChapters.splice(result.source.index, 1);
    newChapters.splice(result.destination.index, 0, reorderedChapter);
    setChapters(newChapters);
    dispatch(bookStoreActions.moveChapter(newChapters));
  };

  return (
    <>
      <BookstoreProvider>
        <DragDropContext onDragEnd={onDragEnd} transitionDuration={10}>
          <Droppable droppableId="chapters">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={styles.main_container}
              >
                {chapters?.map((chapter, index) => (
                  <Draggable
                    key={chapter.id}
                    draggableId={chapter.id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        className={styles.container}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        isDragging={snapshot.isDragging}
                        style={{
                          ...provided.draggableProps.style,
                          transformOrigin: "0 0",
                          transition: "transform 0.1s",
                          left: "100",
                          top: "100",
                        }}
                      >
                        {confirm && (
                          <div className={styles.confirm}>
                            <div className={styles.confirm_content}>
                              <IoMdClose
                                color="#B4B4B4"
                                onClick={() => setConfirm(!confirm)}
                              />
                              <p>Вы действительно хотите удалить главу?</p>
                              <div>
                                <button
                                  className={styles.confirm_button_cancel}
                                  onClick={() => setConfirm(!confirm)}
                                >
                                  Отмена
                                </button>
                                <button
                                  className={styles.confirm_button}
                                  onClick={() => handleDeleteChapter()}
                                >
                                  Удалить
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                        <p className={styles.number}>{index + 1}</p>
                        <input
                          className={styles.chapter_input}
                          type="text"
                          placeholder={`Глава ${index + 1}`}
                          value={chapter.name}
                          required
                          maxLength={50}
                          onChange={(e) =>
                            handlerChangeChapter(e, chapter.id, index)
                          }
                        />
                        <div className={styles.file_div}>
                          <label htmlFor={chapter.id}>
                            <p className={styles.file_p}>
                              {chapter.file ? (
                                <div className={styles.unload}>
                                  <p>{chapter.file?.name}</p>
                                  <GrEdit color="#B4B4B4" />
                                </div>
                              ) : (
                                <div className={styles.unload}>
                                  {" "}
                                  <p>txt / до 2 мб</p>{" "}
                                  <GrAttachment color="#B4B4B4" />{" "}
                                </div>
                              )}
                            </p>
                          </label>
                          <input
                            type="file"
                            id={chapter.id}
                            ref={fileRef}
                            className={styles.file_input}
                            onChange={(e) => handleFileChange(e, chapter.id)}
                          />
                        </div>
                        <IoMdClose
                          color="#B4B4B4"
                          onClick={() => {
                            setConfirm(!confirm);
                            setIdChapter(chapter.id);
                          }}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </BookstoreProvider>
    </>
  );
};