import {  uploadsUrl } from "config";
export const loadFile = async (url, token) => {
  try {
    const response = await fetch(`${uploadsUrl}${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });


    if (!response.ok) {
      throw new Error("Failed to fetch file");
    }

    const blobData = await response.blob();
    const fileName = `${Date.now()}_${Math.random()}`;

    const file = new File([blobData], fileName);


    return file;
  } catch (error) {
    console.error("Error loading file:", error);
    throw new Error("Failed to load file");
  }
};
