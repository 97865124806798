export const modalInitialState = {
    message: "",
    button: 'OK'
}

const modalReducerMap = {
    "SET_MESSAGE": (state, {message, button}) => {
    return {
        ...state,
        message: message,
        button: button ? button : "OK"
        }
    }
}

export function modalReducer(state, action) {
    return modalReducerMap[action.type]?.(state, action.payload) || state
}