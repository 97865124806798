
export const adminStoreInitialState = {
    isAdmin: "",
    authors: []
}


const adminReducerMap = {
    "SET_ISADMIN": (state, {payload}) => {
        return {
            ...state,
            isAdmin: payload
        }
    },
    "GET_AUTHORS": (state, {authors}) => {
        return {
            ...state,
            authors:authors
        }
    }
}

export function adminReducer(state, action){
    return adminReducerMap[action.type]?.(state, action.payload) || state
}