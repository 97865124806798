import React, {useState} from "react";
import style from './timer.module.css'

export const CountDown = ({minutes = 15, seconds = 0 }) => {
    const [paused] = useState(false);
    const [over, setOver] =useState(false);
    const [[ m, s], setTime] =useState([minutes, seconds]);
  
    const tick = () => {
      if (paused || over) return;
  
      if (m === 0 && s === 0) {
        setOver(true);
      } else if (s === 0) {
        setTime([ m - 1, 59]);
      } else {
        setTime([ m, s - 1]);
      }
    };
  
    React.useEffect(() => {
      const timerID = setInterval(() => tick(), 1000);
      return () => clearInterval(timerID);
    });
  
    return (
        <p className={style.timer}>{`${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`}</p>
    );
  };