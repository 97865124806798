import React, { useEffect, useState } from "react";
import styles from "./PersonalDetails.module.css";
import Input from "components/Base/Input/Input";
import Button from "components/Base/Button/Button";
import { useMutation, useQuery } from "@tanstack/react-query";
import { IoMdClose } from "react-icons/io";
import { authorService } from "service/Author.service";
import { checkRole } from "utils/checkRole";
import { adminService } from "service/Admin.service";
import { queryClient } from "index";
import EditEmail from "components/editEmail/EditEmail";

const PersonalDetails = () => {
  const isAdmin = checkRole();
  const [editEmail, setEditEmail] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  const [data, setData] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const { mutate, isSuccess } = useMutation({
    mutationFn: () =>
      isAdmin
        ? adminService.setAdminProfile(data)
        : authorService.setAuthorProfile({ ...data, paymentData: paymentData }),
  });
  const updateEmail = (email) => {
    setData({ ...data, email: email });
  };

  const { data: QueryData } = useQuery({
    queryKey: ["userData"],
    queryFn: () => {
      const data = isAdmin
        ? adminService.getAdminProfile()
        : authorService.getAuthorData();

      return data;
    },
  });

  useEffect(() => {
    if (QueryData) {
      if (isAdmin) {
        setData(QueryData);
      } else {
        setData(QueryData);
        setPaymentData(
          QueryData.paymentData
            ? JSON.parse(QueryData.paymentData)
            : [
                {
                  name: "Кошелек 1",
                  number: "0",
                },
              ]
        );
      }
    }
    if (isSuccess)
      queryClient.invalidateQueries({
        queryKey: ["userData"],
      });
  }, [QueryData, isAdmin, isSuccess]);

  useEffect(() => {
    if (!data?.name || !data?.surname || !data?.alias) {
      setIsDisabled(true);
    } else setIsDisabled(false);
  }, [data]);

  return (
    <div className={styles.personalDetails}>
      {editEmail ? (
        <EditEmail
          editEmail={editEmail}
          oldEmail={data.email}
          onclick={() => setEditEmail()}
          updateEmail={updateEmail}
        />
      ) : (
        <div className={styles.div_input}>
          {isAdmin ? (
            <div className={styles.admins__input}>
              <Input
              readonly={true}
                value={data.email}
                placeholder={"E-mail"}
                onChange={(e) => setData({ ...data, email: e.target.value })}
              />
              <Input
                value={data.password}
                placeholder={"Пароль"}
                onChange={(e) => setData({ ...data, password: e.target.value })}
              />
            </div>
          ) : (
            <>
              <div
                // onClick={() => setEditEmail(!editEmail)}
                style={{ width: "100%", marginLeft: "10px" }}
              >
                <Input
                  readonly={true}
                  value={data.email}
                  placeholder={"E-mail"}
                />
              </div>

              <Input
                value={data.name}
                placeholder={"Имя"}
                onChange={(e) => setData({ ...data, name: e.target.value })}
              />
              {data?.name ? "" : <span>Поле не может быть пустым!</span>}
              <Input
                value={data.surname}
                placeholder={"Фамилия"}
                onChange={(e) => setData({ ...data, surname: e.target.value })}
              />
              {data?.surname ? "" : <span>Поле не может быть пустым!</span>}
              <Input
                value={data.fatherName}
                placeholder={"Отчество"}
                onChange={(e) =>
                  setData({ ...data, fatherName: e.target.value })
                }
              />
              <Input
                value={data.alias}
                placeholder={"Псевдоним"}
                onChange={(e) => setData({ ...data, alias: e.target.value })}
              />
              {data?.alias ? "" : <span>Поле не может быть пустым!</span>}
              <Input
                value={
                  data.birthDate
                    ? new Date(data.birthDate).toISOString().substr(0, 10)
                    : ""
                }
                placeholder={"День рождения"}
                type={"date"}
                onChange={(e) =>
                  setData({ ...data, birthDate: e.target.value })
                }
              />
            </>
          )}
          <Button
            value={"Сохранить"}
            onClick={() => mutate()}
            disabled={isDisabled}
          />
        </div>
      )}
      <div className={styles.div__person__input}>
        <h3>Платежная информация</h3>
        <div className={styles.items}>
          {paymentData?.map((item, i) => (
            <div className={styles.card} key={i}>
              <p>{item.name}</p>
              <Input
                value={item.number}
                onChange={(e) => {
                  const updatedPaymentData = [...paymentData];
                  updatedPaymentData[i] = {
                    ...updatedPaymentData[i],
                    number: e.target.value,
                  };
                  setPaymentData(updatedPaymentData);
                }}
              />
              <IoMdClose
                onClick={() =>
                  setPaymentData((prevData) =>
                    prevData.filter((_, index) => index !== i)
                  )
                }
              />
            </div>
          ))}
        </div>
        <Button
          value={"Добавить кошелек"}
          onClick={() => {
            if (paymentData.length >= 4) return;
            setPaymentData([
              ...paymentData,
              { name: `Кошелек ${paymentData.length + 1}` },
            ]);
          }}
        />
      </div>
    </div>
  );
};

export default PersonalDetails;
