import { useMemo } from "react";

export const useSearch = (search, array) => {
  return useMemo(() => {
    if (!search || !Array.isArray(array)) return array;

    return array.filter((item) =>
      item.name.toLowerCase().includes(search.toLowerCase()),
    );
  }, [search, array]);
};

export const useSlice = (array, type) => {
  return useMemo(() => {
    if (!array?.length) return [];

    const chunkSize = type === "book" ? 4 : 8;
    const result = [];

    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }

    return result;
  }, [array, type]);
};

export const useSort = (sort, array, isDesc) => {
  return useMemo(() => {
    if (!sort || !array?.length) return array;

    if (sort === "published" || sort === "noPublished") {
      if (sort === "noPublished") {
        return array.filter((item) => item?.status === "archived");
      }
      if (sort === "published") {
        return array.filter((item) => item?.status === "published");
      }
    }

    const sortedArray = array
      .slice()
      .sort((a, b) => a[sort]?.localeCompare(b[sort]));

    return isDesc ? sortedArray.reverse() : sortedArray;
  }, [sort, array, isDesc]);
};

export const useFilter = (sort, search, book, author, isDesc) => {
  const filteredBooks = useSearch(search, book);
  const sortedBooks = useSort(sort, filteredBooks, isDesc);
  const slicedBooks = useSlice(sortedBooks, "book");

  const filteredAuthors = useSearch(search, author);
  const sortedAuthors = useSort(sort, filteredAuthors, isDesc);
  const slicedAuthors = useSlice(sortedAuthors, "author");

  return useMemo(() => {
    if (!author?.length) {
      return {
        newBook: slicedBooks,
      };
    }

    return {
      newBook: slicedBooks,
      newAuthor: slicedAuthors,
    };
  }, [author, slicedBooks, slicedAuthors]);
};