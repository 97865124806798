import React, { useEffect, useState } from "react";
import Book from "./Book";
import { uploadsUrl, baseUrl } from "config";
import axios from "axios";
import Genre from "components/genre/Genre";
import { BookstoreProvider } from "providers/BookStoreProvider";
const FullBook = ({ id, count, img, name, authorName, genres, actionInfo, restriction, showCount, status, updatedAt }) => {
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [genreShow, setGenreShow] = useState(false);
  const [, setShow] = useState(false);
  const [genresN, setGenres] = useState([]);
  useEffect(() => {
    (async () => {
      const res = await axios.get(`${baseUrl}/genres`);
      setGenres(res.data);
    })();
  }, []);
  return (
    <BookstoreProvider>
      <Book
        showCount={showCount}
        count={count}
        id={id}
        img={`${uploadsUrl}${img}`}
        name={name}
        authorName={authorName}
        genres={genres}
        actionInfo={actionInfo}
				state={isUpdateMode}
				setState={setIsUpdateMode}
				restriction={restriction}
        setGenreShow={setGenreShow}
        setGenres={setGenres}
        allGenres={genresN}
        status={status}
        updatedAt={updatedAt}
      />
      {genreShow ? (
        <Genre
          setShow={setShow}
          setGenreShow={setGenreShow}
          genres={genresN}
          setGenres={setGenres}
        />
      ) : null}
    </BookstoreProvider>
  );
};

export default FullBook;