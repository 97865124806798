import React from "react";

const Person = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.2851 8.03199C11.024 7.45382 11.5633 6.66097 11.8281 5.76376C12.0928 4.86654 12.0698 3.90957 11.7622 3.02598C11.4545 2.14239 10.8777 1.37612 10.1118 0.833783C9.34586 0.291443 8.42903 0 7.48882 0C6.54861 0 5.63178 0.291443 4.86588 0.833783C4.09998 1.37612 3.52309 2.14239 3.21548 3.02598C2.90787 3.90957 2.88483 4.86654 3.14957 5.76376C3.41431 6.66097 3.95366 7.45382 4.69258 8.03199C3.42642 8.53648 2.32165 9.37323 1.49604 10.453C0.670442 11.5328 0.154958 12.8152 0.00454622 14.1635C-0.00634133 14.2619 0.00237294 14.3615 0.0301913 14.4566C0.0580097 14.5517 0.104387 14.6404 0.166677 14.7176C0.292475 14.8737 0.475448 14.9736 0.675342 14.9955C0.875237 15.0173 1.07568 14.9593 1.23257 14.8342C1.38947 14.7091 1.48996 14.5272 1.51195 14.3284C1.67746 12.8631 2.37998 11.5098 3.48531 10.5271C4.59064 9.54445 6.02128 9.0012 7.5039 9.0012C8.98651 9.0012 10.4171 9.54445 11.5225 10.5271C12.6278 11.5098 13.3303 12.8631 13.4958 14.3284C13.5163 14.5125 13.6047 14.6826 13.7439 14.8058C13.8831 14.929 14.0632 14.9966 14.2495 14.9955H14.3324C14.53 14.9729 14.7106 14.8735 14.8348 14.7191C14.9591 14.5646 15.0169 14.3676 14.9957 14.171C14.8446 12.8189 14.3263 11.5332 13.4965 10.4517C12.6666 9.37029 11.5565 8.53381 10.2851 8.03199ZM7.48882 7.4998C6.89255 7.4998 6.30966 7.32395 5.81388 6.9945C5.3181 6.66504 4.93168 6.19678 4.7035 5.64892C4.47531 5.10105 4.41561 4.4982 4.53194 3.91659C4.64826 3.33499 4.9354 2.80074 5.35703 2.38143C5.77866 1.96211 6.31584 1.67656 6.90066 1.56087C7.48548 1.44518 8.09166 1.50455 8.64254 1.73149C9.19343 1.95842 9.66428 2.34271 9.99555 2.83578C10.3268 3.32884 10.5036 3.90852 10.5036 4.50153C10.5036 5.29672 10.186 6.05934 9.62062 6.62163C9.05523 7.18391 8.2884 7.4998 7.48882 7.4998Z" fill="currentColor" />
  </svg>
  );
};

export default Person;
