import React, { memo } from "react";

export const SearchIcon = memo(() => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.55502 10.3458C8.54549 11.1791 7.25116 11.6796 5.83993 11.6796C2.61462 11.6796 0 9.06507 0 5.83982C0 2.61458 2.61462 0 5.83993 0C9.06523 0 11.6799 2.61458 11.6799 5.83982C11.6799 7.25115 11.1792 8.54555 10.3458 9.55511L12.8362 12.0455C13.0546 12.2639 13.0546 12.6179 12.8362 12.8362C12.6179 13.0546 12.2638 13.0546 12.0455 12.8362L9.55502 10.3458ZM1.11828 5.83982C1.11828 3.23218 3.23223 1.11826 5.83993 1.11826C8.44762 1.11826 10.5616 3.23218 10.5616 5.83982C10.5616 7.11504 10.056 8.27217 9.2344 9.12176C9.21337 9.13754 9.19319 9.15499 9.17406 9.17413C9.15493 9.19325 9.13748 9.21342 9.12171 9.23444C8.27213 10.0559 7.11506 10.5614 5.83993 10.5614C3.23223 10.5614 1.11828 8.44747 1.11828 5.83982Z"
      fill="#B4B4B4"
    />
  </svg>
));
