import * as Types from "./type";

import UUID from "react-uuid";

export const bookStoreInitialState = {
  name: "",
  authorName: "",
  description: "",
  restriction: false,
  genres: [],
  img: null,
  imgVisible: null,
  isEditMode: false,
  chapters: [],
};


const bookReducerMap = {
  [Types.CHANGE_FIELD]: (state, { field, value }) => {
    return {
      ...state,
      [field]: value,
    };
  },

  [Types.TOGGLE_GENRE]: (state, { genreId }) => {
    const newGenres = state.genres ? new Set(state.genres) : new Set();
    if (!genreId){
      return {
        ...state,
        genres: [],
      };
    }
    if (state.genres.length >= 3 && !newGenres.has(genreId)) {
      return {
        ...state,
        genres: state.genres,
      };
    } 
    if (newGenres.has(genreId)) {
      newGenres.delete(genreId);
    } else {
      newGenres.add(genreId);
    }
    return {
      ...state,
      genres: Array.from(newGenres),
    };
  },

  [Types.ADD_CHAPTER]: (state, { value, file, index }) => {
    return {
      ...state,
      chapters: {
        ...state.chapters,
        [UUID()]: {
          name: value ? value : "",
          file: file ? file : undefined,
          index: index ? index : undefined
        },
      },
    };
  },

  [Types.DELETE_CHAPTER]: (state, { chapterId }) => {
    const updatedChapters = { ...state.chapters };
    delete updatedChapters[chapterId];

    return {
      ...state,
      chapters: updatedChapters,
    };
  },

  [Types.MOVE_CHAPTERS]: (state, { newChapters }) => {
    return {
      ...state,
      chapters: newChapters,
    };
  },
 

  [Types.CHANGE_CHAPTER]: (state, { id, field, value}) => {
    return {
      ...state,
      chapters: {
        ...state.chapters,
        [id]: {
          ...state.chapters[id],
          [field]: value,
        },
      },
    };
  },

  

 
  [Types.CREATE_START]: () => {
    // return bookStoreInitialState;
  },

  [Types.EDIT_START]: (_, { book }) => {
    return { ...book, isEditMode: true };
  },
	[Types.DELETE_DATA]: () => {
		return bookStoreInitialState
	}
};

export function bookReducer(state, action) {
  return bookReducerMap[action.type]?.(state, action.payload) || state;
}
